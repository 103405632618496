import { ethers } from "ethers";
import _ from "lodash";

let store = {};
export const getMetaMask = () => store;

if (window.ethereum && window.ethereum.isMetaMask) {
  window.ethereum.on("chainChanged", (chainId) => {
    console.log("chainChanged", chainId);
    window.location.reload();
  });

  window.ethereum.on("accountsChanged", (account) => {
    console.log("accountsChanged", account);
    window.location.reload();
  });
}

export const changeChain = async (chainId_) => {
  const chainId = "0x" + (+chainId_).toString(16);
  await window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId }], // chainId must be in hexadecimal numbers
  });
};

export const fetchSigner = async () => {
  store = {};
  if (!(window.ethereum && window.ethereum.isMetaMask)) {
    throw new Error("No Ethereum");
  }
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  await provider.send("eth_requestAccounts", []);

  const [network, signer] = await Promise.all([
    provider.getNetwork(),
    provider.getSigner(),
  ]);
  const signerAddress = await signer.getAddress();
  store = { provider, network, signer, signerAddress };
};

export const silentConnectable = async (chainId) => {
  const hasWallet = window.ethereum && window.ethereum.isMetaMask;
  if (!hasWallet) {
    return false;
  }

  try {
    const [permissions, isUnlocked, chainId_] = await Promise.all([
      window.ethereum.request({
        method: "wallet_getPermissions",
      }),
      window.ethereum._metamask.isUnlocked(),
      window.ethereum.request({ method: "eth_chainId" }),
    ]);
    if (
      permissions.length > 0 &&
      isUnlocked &&
      "0x" + chainId.toString(16) === chainId_
    ) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

export const setContract = (func, name) => {
  const index = ["contracts", name];
  if (_.has(store, index)) {
    return _.get(store, index);
  } else {
    if (store.provider) {
      const contract = func(store.provider).connect(store.signer);
      _.set(store, index, contract);
      return contract;
    }
  }
};

export const getContract = (name) => {
  return _.get(store, ["contracts", name]);
};
