import React from "react";
import Paper from "@mui/material/Paper";
import Header from "common/Header";
import Footer from "common/Footer";
import { Outlet } from "react-router-dom";
import { ERC20MetaProvider } from "context/ERC20MetaContext";
import { PositionDetailProvider } from "context/PositionDetailContext";
import { PoolSqrtPriceProvider } from "context/PoolSqrtPriceContext";
import { LendingOfferProvider } from "context/LendingOfferContext";
import { ConsultantProvider } from "context/ConsultantContext";

export const MainApp = () => {
  return (
    <ConsultantProvider>
      <LendingOfferProvider>
        <PoolSqrtPriceProvider>
          <ERC20MetaProvider>
            <PositionDetailProvider>
              <Paper
                sx={{
                  textAlign: "left",
                  backgroundColor: "rgba(255, 255, 255, 0)",
                }}
                elevation={0}
              >
                <Header isApp={true} />
                <Outlet />
                <Footer />
              </Paper>
            </PositionDetailProvider>
          </ERC20MetaProvider>
        </PoolSqrtPriceProvider>
      </LendingOfferProvider>
    </ConsultantProvider>
  );
};
