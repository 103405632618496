import React, { useContext, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { PageBox } from "utils/PageBox";
import Box from "@mui/material/Box";
import { useTitle } from "react-use";
import { TextField } from "@mui/material";
import { useChainSetting, useTokens } from "common/hooks";
import { mathTool } from "lp-lending-contract";
import { InLink, useForceTrailingSlash } from "utils/Link";
import { chainSettings } from "common/chainSettings";
import _ from "lodash";

const AppHome = () => {
  const { chainId, chainName } = useChainSetting();
  useForceTrailingSlash();

  useTitle("LiquidityLending @ Poption - App Home");

  return (
    <PageBox alpha={0.7}>
      <Box sx={{ typography: "h2" }}>Welcome to LiquidityLending</Box>
      <Box sx={{ typography: "h6" }}>
        <p>
          Select your preferred blockchain network and choose between lending or
          borrowing liquidity tokens.
        </p>
        <Box sx={{ typography: "h4" }}>Navigate to:</Box>
        <ul style={{}}>
          <li>
            <InLink href={`/app/${chainId}/lend`}>Lend Liquidity Tokens</InLink>
          </li>
          <li>
            <InLink href={`/app/${chainId}/borrow`}>
              Borrow Liquidity Tokens (Long the Impermanent Loss)
            </InLink>
          </li>
        </ul>
        <Box sx={{ typography: "h4" }}>Switch Chain:</Box>
        <ul style={{}}>
          <li>{chainName} (Current Chain)</li>
          {_.map(
            _.filter(
              chainSettings,
              (chainSetting) => chainSetting.chainId !== +chainId
            ),
            (chainSetting) => (
              <li key={chainSetting.chainId}>
                <InLink href={`/app/${chainSetting.chainId}`}>
                  {chainSetting.chainName}
                </InLink>
              </li>
            )
          )}
        </ul>
      </Box>
    </PageBox>
  );
};

export default AppHome;
