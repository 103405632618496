import { Routes, Route } from "react-router-dom";
import { Index } from "features/Index";
import { MainApp } from "features/MainApp";
import PositionList from "features/PositionList";
import LendPosition from "features/LendPosition";
import BorrowPosition from "features/BorrowPosition";
import AppHome from "features/AppHome";
import LendingOfferList from "features/LendingOfferList";
import usePageTracking from "utils/usePageTracking";
import "./App.css";

function App() {
  usePageTracking();
  return (
    <Routes>
      <Route path="" element={<Index />} />
      <Route path="app/:chainId" element={<MainApp />}>
        <Route path="" element={<AppHome />} />
        <Route path="lend" element={<PositionList />} />
        <Route path="lend/:tokenId" element={<LendPosition />} />
        <Route path="borrow/" element={<LendingOfferList />} />
        <Route path="borrow/:lendingOfferId" element={<BorrowPosition />} />
      </Route>
    </Routes>
  );
}

export default App;
