const mathTool = require("./mathTool");

module.exports = {
  artifacts: {
    IERC20Metadata: require("./artifacts/@openzeppelin/contracts/token/ERC20/extensions/IERC20Metadata.sol/IERC20Metadata.json"),
    LiquidityLending: require("./artifacts/contracts/LiquidityLending.sol/LiquidityLending.json"),
    IUniswapV3PositionManager: require("./artifacts/contracts/IUniswapV3PositionManager.sol/IUniswapV3PositionManager.json"),
    IUniswapV3Pool: require("./artifacts/contracts/IUniswapV3Pool.sol/IUniswapV3Pool.json"),
    IUniswapV3PoolFactory: require("./artifacts/contracts/IUniswapV3Factory.sol/IUniswapV3Factory.json"),
  },
  mathTool,
};
