import { createContext, useContext, useState, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";

export const encodeEthAddressToBase64UrlParam = (ethAddress) => {
  // Remove the '0x' prefix from the Ethereum address
  const hexString = ethAddress.replace(/^0x/, "");

  // Convert the hex string to a byte array
  const byteArray = new Uint8Array(
    hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
  );

  // Encode the byte array to Base64
  const base64String = btoa(String.fromCharCode.apply(null, byteArray));

  // Make the Base64 string URL-safe using encodeURIComponent
  return encodeURIComponent(base64String);
};

export const decodeBase64UrlParamToEthAddress = (encoded) => {
  if (!encoded) {
    return null;
  }
  // Decode the URL-safe Base64 string using decodeURIComponent
  const base64String = decodeURIComponent(encoded);

  // Decode the Base64 string to a byte array
  const byteArray = new Uint8Array(
    atob(base64String)
      .split("")
      .map((char) => char.charCodeAt(0))
  );

  // Convert the byte array to a hex string
  const hexString = Array.from(byteArray)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");

  // Add the '0x' prefix to the Ethereum address
  return "0x" + hexString;
};

const ConsultantContext = createContext();

export const useRefAddress = () => {
  const userAddress = useSelector((state) =>
    _.get(state, "ethers.wallet.data.address")
  );
  return useMemo(() => {
    if (userAddress) {
      return encodeEthAddressToBase64UrlParam(userAddress);
    }
  }, [userAddress]);
};

export const useDecorateRefAddress = () => {};

export const useConsultant = () => useContext(ConsultantContext);

export const ConsultantProvider = ({ children }) => {
  const [searchParams] = useSearchParams();
  const reference = decodeBase64UrlParamToEthAddress(searchParams.get("ref"));
  const [introducer, setIntroducer] = useState(
    localStorage.getItem("introducer")
  );
  const userAddress = useSelector((state) =>
    _.get(state, "ethers.wallet.data.address")
  );

  useEffect(() => {
    if (reference && !introducer) {
      setIntroducer(reference);
      localStorage.setItem("consultant", reference);
    }
  }, [reference, introducer]);

  return (
    <ConsultantContext.Provider
      value={{ introducer, consultant: reference || introducer || userAddress }}
    >
      {children}
    </ConsultantContext.Provider>
  );
};
