import { createContext, useState, useMemo } from "react";
import { getProvider } from "common/providers";
import { ethers } from "ethers";
import { artifacts } from "lp-lending-contract";
import moize from "moize";
import { useChainSetting } from "common/hooks";
import _ from "lodash";

export const LendingOfferContext = createContext();

const useLendingOfferdata = () => {
  const { chainId, liquidityLendingAddress } = useChainSetting();
  const [lendingOffers, setLendingOffers] = useState({});

  const fetchLendingOffer = useMemo(() => {
    const provider = getProvider(chainId);
    const liquidityLending = new ethers.Contract(
      liquidityLendingAddress,
      artifacts.LiquidityLending.abi,
      provider
    );
    return moize.promise(
      async (tokenId) => {
        console.log("fetchLendingOffer", tokenId);
        const lendingOffer = await liquidityLending.lendingOffers(tokenId);
        setLendingOffers((oldStorage) => {
          return {
            ...oldStorage,
            [tokenId]: lendingOffer,
          };
        });
        return lendingOffer;
      },
      {
        transformArgs: (args) => {
          const key = args[0].toString();
          return key;
        },
        maxSize: 100,
      }
    );
  }, [chainId, liquidityLendingAddress]);
  return [fetchLendingOffer, lendingOffers];
};

export const LendingOfferProvider = ({ children }) => {
  const data = useLendingOfferdata();
  return (
    <LendingOfferContext.Provider value={data}>
      {children}
    </LendingOfferContext.Provider>
  );
};
