import React, { useState } from "react";
import { ConnectButton } from "../../common/ConnectButton";
import { useDispatch } from "react-redux";
import { act } from "./slice";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import _ from "lodash";

export const WalletButton = (props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  if (props.disableText && props.disableText !== "") {
    return (
      <ConnectButton
        disabled
        sx={{ width: "100%", textTransform: "none" }}
        variant="contained"
      >
        {props.disableText}
      </ConnectButton>
    );
  }
  const props_ = _.omit(props, [
    "disableText",
    "getPromise",
    "name",
    "title",
    "body",
    "afterSubmit",
  ]);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>{props.name}</DialogTitle>
        <Paper
          elevation={0}
          sx={{ marginX: "1em", marginBottom: "1em", paddingX: "1em" }}
        >
          {props.body}
        </Paper>
      </Dialog>
      <ConnectButton
        {...props_}
        sx={{ width: "100%" }}
        variant="contained"
        onClick={async () => {
          setOpen(true);
          const res = await dispatch(
            act({
              promise: props.getPromise(),
              title: props.title,
            })
          );
          console.log(res);
          if (res.type === "transaction/act/fulfilled" && props.afterSubmit) {
            props.afterSubmit();
          }
          setOpen(false);
        }}
      >
        {props.name}
      </ConnectButton>
    </>
  );
};
