import React, { useCallback, useMemo } from "react";
import { WalletButton } from "./WalletButton";
import { getMetaMask } from "common/metamask";
import { useChainSetting } from "common/hooks";
import { artifacts } from "lp-lending-contract";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import _ from "lodash";

export const WithLiquidityLendingButton = (props) => {
  const { liquidityLendingAddress } = useChainSetting();
  const ready = useSelector((state) =>
    _.get(state, "ethers.wallet.data.address")
  );
  const contract = useMemo(() => {
    if (ready) {
      return new ethers.Contract(
        liquidityLendingAddress,
        artifacts.LiquidityLending.abi,
        getMetaMask().signer
      );
    }
  }, [ready, liquidityLendingAddress]);

  const getPromise = useCallback(
    () => props.callContract(contract),
    [contract, props]
  );

  return (
    <WalletButton
      {..._.omit(props, ["callContract"])}
      getPromise={getPromise}
      disableText={ready ? props.disableText : "Connecting..."}
    />
  );
};

export const WithPositionManagerButton = (props) => {
  const { positionManagerAddress } = useChainSetting();
  const ready = useSelector((state) =>
    _.get(state, "ethers.wallet.data.address")
  );
  const contract = useMemo(() => {
    if (ready) {
      return new ethers.Contract(
        positionManagerAddress,
        artifacts.IUniswapV3PositionManager.abi,
        getMetaMask().signer
      );
    }
  }, [ready, positionManagerAddress]);

  const getPromise = useCallback(
    () => props.callContract(contract),
    [contract, props]
  );

  return (
    <WalletButton
      {..._.omit(props, ["callContract"])}
      getPromise={getPromise}
      disableText={ready ? props.disableText : "Connecting..."}
    />
  );
};
export const WithERC20MetadataButton = (props) => {
  const { address } = props;
  const ready = useSelector((state) =>
    _.get(state, "ethers.wallet.data.address")
  );
  const contract = useMemo(() => {
    if (ready) {
      return new ethers.Contract(
        address,
        artifacts.IERC20Metadata.abi,
        getMetaMask().signer
      );
    }
  }, [ready, address]);

  const getPromise = useCallback(
    () => props.callContract(contract),
    [contract, props]
  );

  return (
    <WalletButton
      {..._.omit(props, ["callContract"])}
      getPromise={getPromise}
      disableText={ready ? props.disableText : "Connecting..."}
    />
  );
};
/**
export const MintButton = (props) => {
  const { ctype, number, info, signature } = props;
  const title = `Mint ${ctype} No.${number}.`;

  return (
    <WithContributionButton
      {...props}
      name="Mint"
      title={title}
      callcontribution={(contribution) =>
        contribution.mint(ctype, number, info, signature)
      }
    />
  );
};

      getPromise={() => contribution.setCreatorPrice(tokenId, price)}
        contribution.setLocked(tokenid, tolocked)
       contribution.buy(tokenId, addAmount)
       contribution.buyBack(tokenId, addAmount)
*/
