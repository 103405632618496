import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { artifacts } from "lp-lending-contract";
import * as wallet from "./metamask";

const geneCondition = ([stateKey, key]) => {
  return (arg, { getState }) => {
    const reco = getState().ethers[stateKey];
    return (
      !(reco && reco[key] === arg.args[key] && reco.status !== "rejected") ||
      !!arg.overwrite
    );
  };
};

export const fetchSigner = createAsyncThunk(
  "ethers/fetchSinger",
  async ({ chainId }, thunkAPI) => {
    await wallet.fetchSigner(chainId);
    const mm = wallet.getMetaMask();
    if (mm.network.chainId !== chainId) {
      await wallet.changeChain(chainId);
      throw new Error(
        `ChainId not match: expect ${mm.network.chainId} to equal ${chainId}`
      );
    }
    return {
      chainId: mm.network.chainId,
      name: mm.network.name,
      address: mm.signerAddress,
    };
  },
  {
    condition: geneCondition("wallet", "chainId"),
  }
);

const initialState = {};

const slice = createSlice({
  name: "ethers",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    prepareWallet(state, action) {
      if (
        state.constant &&
        state.wallet &&
        state.constant.status === "fulfilled" &&
        state.wallet.status === "fulfilled"
      ) {
        wallet.setContract((provider) => {
          return new ethers.Contract(
            state.constant.liquidityLending.address,
            artifacts.LiquidityLending.abi,
            provider
          );
        }, "liquidityLending");
        state.wallet.contractReady = true;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSigner.pending, (state, action) => {
        state.wallet = {
          status: "pending",
          chainId: action.meta.arg.chainId,
        };
      })
      .addCase(fetchSigner.fulfilled, (state, action) => {
        state.wallet.status = "fulfilled";
        state.wallet.data = action.payload;
      })
      .addCase(fetchSigner.rejected, (state, action) => {
        state.wallet.error = action.error;
        state.wallet.status = "rejected";
      });
  },
});

export const actions = slice.actions;
export default slice.reducer;

export const getSignerAddress = (state) => {
  return state.ethers.wallet && state.ethers.wallet.status === "fulfilled"
    ? state.ethers.wallet.data.address
    : null;
};

export const getReady = (state) => {
  return state.ethers.constant && state.ethers.constant.status === "fulfilled";
};
