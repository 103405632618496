import React, { useContext, useEffect } from "react";
import {
  MyPositionContext,
  MyPositionProvider,
} from "context/MyPositionContext";
import { ERC20MetaContext } from "context/ERC20MetaContext";
import { PageBox } from "utils/PageBox";
import Box from "@mui/material/Box";
import { useTitle } from "react-use";
import _ from "lodash";
import { useTable } from "react-table";
import { InLink } from "utils/Link";
import { useChainSetting, prepareTokens } from "common/hooks";
import { shortAddress } from "utils/tools";
import { mathTool } from "lp-lending-contract";
import { documentHost } from "common/chainSettings";
import { useSelector } from "react-redux";
import { getSignerAddress } from "common/slice";
const { BigFloat, BF } = mathTool;

const PositionTable = ({ positions, tokensMetadata }) => {
  // Process the data
  const data = React.useMemo(() => {
    return Object.entries(positions).map(([tokenId, position]) => {
      const tokens = prepareTokens(position, tokensMetadata);
      if (tokens) {
        return {
          tokenId,
          token0: `${tokens.token0.name} (${tokens.token0.symbol})`,
          token1: `${tokens.token1.name} (${tokens.token1.symbol})`,
          fee: `${position.fee / 10000}%`,
          priceLower: BigFloat.pow(1.0001, BF(position.tickLower))
            .div(tokens.sqrtPriceScale.pow(2))
            .toNumber(),
          priceUpper: BigFloat.pow(1.0001, BF(position.tickUpper))
            .div(tokens.sqrtPriceScale.pow(2))
            .toNumber(),
          liquidity: BF(position.liquidity)
            .div(tokens.liquidityScale)
            .toNumber(),
        };
      } else {
        return {
          tokenId,
          token0: shortAddress(position.token0),
          token1: shortAddress(position.token1),
          fee: `${position.fee / 10000}%`,
          priceLower: BigFloat.pow(1.0001, BF(position.tickLower)).toNumber(),
          priceUpper: BigFloat.pow(1.0001, BF(position.tickUpper)).toNumber(),
          liquidity: BF(position.liquidity).toNumber(),
        };
      }
    });
  }, [positions, tokensMetadata]);

  // Define table columns
  const columns = React.useMemo(
    () => [
      { Header: "Token ID", accessor: "tokenId" },
      { Header: "Token 0", accessor: "token0" },
      { Header: "Token 1", accessor: "token1" },
      { Header: "Fee", accessor: "fee" },
      { Header: "Min Price", accessor: "priceLower" },
      { Header: "Max Price", accessor: "priceUpper" },
      { Header: "Liquidity", accessor: "liquidity" },
      {
        Header: "Lend",
        id: "lend",
        accessor: "tokenId",
        Cell: ({ cell: { value } }) => (
          <InLink href={`${value}`}>Lend Position</InLink>
        ),
      },
    ],
    []
  );

  // Set up the table instance
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  // Render the table
  return (
    <table {...getTableProps()} style={{ border: "solid 1px black" }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{
                  borderBottom: "solid 1px black",
                  background: "aliceblue",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: "10px",
                      border: "solid 1px gray",
                      background: "papayawhip",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const PositionList = () => {
  return (
    <MyPositionProvider>
      <PositionListInner />
    </MyPositionProvider>
  );
};

export const PositionListInner = () => {
  const positions = useContext(MyPositionContext);
  const [fetchERC20, tokensMetadata] = useContext(ERC20MetaContext);
  const user = useSelector(getSignerAddress);

  useTitle("Poption Contribution - All Contribution Types");
  const { testPool } = useChainSetting();

  useEffect(() => {
    _.map(positions, (position) => {
      fetchERC20(position.token0);
      fetchERC20(position.token1);
    });
  }, [positions, fetchERC20]);
  return (
    <PageBox>
      <Box sx={{ typography: "h4" }}>Lend Your Test Positions</Box>
      <p>
        Welcome to our platform, where you can lend your Uniswap v3 liquidity
        pool positions and explore the potential of our services. To ensure a
        safe and enjoyable experience, we've provided two test tokens:{" "}
        <a
          href={`${documentHost}/en/test_token.html`}
          target="_blank"
          rel="noopener noreferrer"
        >
          mTestUSD and mTest2
        </a>
        . These tokens allow you to simulate the lending process without risking
        your valuable assets.
      </p>
      <p>
        To get started, simply add liquidity to the Uniswap v3 pool using
        mTestUSD and mTest2, and then lend your position on our platform.
      </p>
      {user ? (
        <>
          <p>
            {" "}
            Click{" "}
            <a
              href={`https://app.uniswap.org/#/add/${testPool[0]}/${testPool[1]}/${testPool[2]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{" "}
            to access the Uniswap interface and set up your position.
          </p>
          <p>
            You can also view your other liquidity positions as well, but please
            avoid using them since the contract is not audited yet.
          </p>
          {positions ? (
            <PositionTable
              positions={positions}
              tokensMetadata={tokensMetadata}
            />
          ) : (
            "Loading..."
          )}
        </>
      ) : (
        "Please connect wallet first."
      )}
    </PageBox>
  );
};

export default PositionList;
