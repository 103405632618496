import React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Header from "common/Header";
import Footer from "common/Footer";
import { InLink, OutLink } from "utils/Link";
import { useTitle } from "react-use";
import { defaultChain, documentHost } from "common/chainSettings";

export const Index = () => {
  useTitle("LiquidityLending @ Poption");
  return (
    <Paper
      sx={{
        textAlign: "left",
        backgroundColor: "rgba(255, 255, 255, 0)",
      }}
      elevation={0}
    >
      <Header isApp={false} />
      <Paper
        sx={{ padding: "1em", backgroundColor: "rgba(255, 255, 255, 0.2)" }}
      >
        <Grid container>
          <Grid item xs={0} md={1} lg={2} />
          <Grid item xs={12} md={10} lg={8}>
            <Stack>
              <Paper
                sx={{
                  margin: "3em",
                  padding: "20px",
                  paddingTop: "0",
                  marginTop: "1em",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                }}
                elevation={0}
              >
                <Box sx={{ typography: "h2" }}>LiquidityLending</Box>
                <Box sx={{ typography: "h6" }}>
                  <p>
                    Welcome to LiquidityLending, a cutting-edge and
                    user-friendly platform tailored for Uniswap v3. We empower
                    liquidity providers and borrowers to unlock their full
                    potential in the decentralized finance ecosystem. With
                    LiquidityLending, providers can lend their tokens to
                    increase their passive income while minimizing impermanent
                    loss exposure. Meanwhile, borrowers have the opportunity to
                    long the impermanent loss and capitalize on market
                    fluctuations, perfecting their trading strategies. Embrace
                    the future of liquidity lending with LiquidityLending today!
                  </p>
                  <p></p>
                  <Box sx={{ typography: "h4" }}>Documentation and Guide</Box>
                  <ul style={{}}>
                    <li>
                      <InLink href={`/app/${defaultChain}/lend`}>
                        Lend your liquidity tokens and increase your passive
                        income.
                      </InLink>
                    </li>
                    <li>
                      <InLink href={`/app/${defaultChain}/borrow`}>
                        Borrow liquidity tokens and long the impermanent loss.
                      </InLink>
                    </li>
                    <li>
                      <OutLink href={`${documentHost}/en/index.html`}>
                        Access our comprehensive documentation for further
                        guidance.
                      </OutLink>
                    </li>
                  </ul>
                </Box>
              </Paper>
            </Stack>
          </Grid>
          <Grid item xs={0} md={1} lg={2} />
        </Grid>
      </Paper>
      <Footer />
    </Paper>
  );
};

export default Index;
