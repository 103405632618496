import { createContext, useState, useMemo } from "react";
import { getProvider } from "common/providers";
import { ethers } from "ethers";
import { artifacts } from "lp-lending-contract";
import moize from "moize";
import { useChainSetting } from "common/hooks";
import _ from "lodash";

export const PositionDetailContext = createContext();

const usePositionDetaildata = () => {
  const { chainId, positionManagerAddress, poolFactoryAddress } =
    useChainSetting();
  const [positions, setPositions] = useState({});

  const fetchPosition = useMemo(() => {
    const provider = getProvider(chainId);
    const positionManager = new ethers.Contract(
      positionManagerAddress,
      artifacts.IUniswapV3PositionManager.abi,
      provider
    );
    const poolFactory = new ethers.Contract(
      poolFactoryAddress,
      artifacts.IUniswapV3PoolFactory.abi,
      provider
    );
    return moize.promise(
      async (tokenId) => {
        console.log("fetchPosition", tokenId);
        const [position, ownerAddress] = await Promise.all([
          positionManager.positions(tokenId),
          positionManager.ownerOf(tokenId),
        ]);
        const pool = await poolFactory.getPool(
          position.token0,
          position.token1,
          position.fee
        );
        const res = {
          ..._.pick(position, [
            "nonce",
            "operator",
            "token0",
            "token1",
            "fee",
            "tickLower",
            "tickUpper",
            "liquidity",
            "feeGrowthInside0LastX128",
            "feeGrowthInside1LastX128",
            "tokensOwed0",
            "tokensOwed1",
          ]),
          ownerAddress,
          pool,
        };
        setPositions((oldPositions) => {
          return {
            ...oldPositions,
            [tokenId]: res,
          };
        });
        return res;
      },
      {
        transformArgs: (args) => {
          const key = args[0].toString();
          return key;
        },
        maxSize: 100,
        maxAge: 1000 * 30,
      }
    );
  }, [chainId, positionManagerAddress, poolFactoryAddress]);
  return [fetchPosition, positions];
};

export const PositionDetailProvider = ({ children }) => {
  const data = usePositionDetaildata();
  return (
    <PositionDetailContext.Provider value={data}>
      {children}
    </PositionDetailContext.Provider>
  );
};
