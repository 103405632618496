import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

export const PageBox = (props) => {
  return (
    <Paper
      sx={{
        paddingX: "1em",
        paddingY: "3em",
        backgroundColor: `rgba(255, 255, 255, ${props.alpha || 0.2})`,
      }}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={0} md={2} />
        <Grid item xs={12} md={8}>
          <Box sx={{ typography: "h2" }}>{props.title}</Box>
          {props.children}
        </Grid>
        <Grid item xs={0} md={2} />
      </Grid>
    </Paper>
  );
};
