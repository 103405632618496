import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import logo from "../img/logo1024.png";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { OutLink, InLink } from "../utils/Link";
import Button from "@mui/material/Button";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import { DiscordIcon } from "../utils/Icons";
import { useHref } from "react-router-dom";
import { linkSx, bgColor, shortAddress } from "../utils/tools";
import { ConnectButton } from "./ConnectButton";
import { useSelector, useDispatch } from "react-redux";
import { actions, fetchSigner, getSignerAddress } from "./slice";
import { useChainSetting } from "./hooks";
import { TransactionButton } from "../features/transaction/TransactionButton";
import { TransactionDialog } from "../features/transaction/TransactionDialog";
import { ErrorDialog } from "../features/transaction/ErrorDialog";
import { silentConnectable, getMetaMask } from "./metamask";
import { defaultChain } from "common/chainSettings";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useLocation } from "react-router-dom";
import { chainSettings, documentHost } from "common/chainSettings";
import _ from "lodash";

const WhichChain = () => {
  const { chainId } = useChainSetting();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wallet = useSelector((state) => state.ethers.wallet);
  const fulfilled = useSelector((state) => state.transaction.counter.fulfilled);
  useEffect(() => {
    silentConnectable(chainId).then((hasWallet) => {
      if (hasWallet) {
        dispatch(fetchSigner({ chainId }));
      }
    });
    if (getMetaMask().signerAddress) {
      dispatch(fetchSigner({ chainId }));
    }
  }, [dispatch, chainId]);

  useEffect(() => {
    if (wallet && wallet.status === "fulfilled" && !wallet.contractReady) {
      dispatch(actions.prepareWallet());
      //      dispatch(fetchBasicVariable());
    }
  }, [dispatch, wallet]);

  useEffect(() => {
    if (wallet && wallet.status === "fulfilled") {
      //     dispatch(fetchBasicVariable({ overwrite: true }));
    }
  }, [dispatch, wallet, fulfilled]);

  const currentPath = useLocation();

  const handleChange = (event) => {
    event.preventDefault();
    const regex = new RegExp(`^/app/${chainId}`);
    const newPath = currentPath.pathname.replace(
      regex,
      `/app/${event.target.value}`
    );
    navigate(newPath);
    // Add logic to switch the chain and update the state
  };

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <TransactionDialog />
      <ErrorDialog />
      <Grid item xs={6} md={6}>
        <Select
          value={chainId}
          onChange={handleChange}
          variant="standard"
          label="Choose Chain"
          disableUnderline={true}
        >
          {_.map(chainSettings, (chainSetting) => (
            <MenuItem value={chainSetting.chainId} key={chainSetting.chainId}>
              {chainSetting.chainName}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={6} md={6}>
        <TransactionButton />
      </Grid>
    </Grid>
  );
};

const ChainWallet = ({ chainId }) => {
  const user = useSelector(getSignerAddress);

  return (
    <ConnectButton
      variant="outlined"
      sx={{ width: "100%", textTransform: "none" }}
    >
      {shortAddress(user || "")}
    </ConnectButton>
  );
};

export const Header = ({ isApp }) => {
  const appLink = useHref(`/app/${defaultChain}`);
  const chainSetting = useChainSetting();

  return (
    <Grid
      container
      alignItems="center"
      spacing={2}
      sx={{ backgroundColor: bgColor }}
    >
      <Grid item xs={12} md={3}>
        <InLink
          href={useHref("/")}
          sx={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Avatar component="span" variant="square" src={logo} />
            <Box sx={{ typography: "h4" }}>LiquidityLending (alpha)</Box>
          </Stack>
        </InLink>
      </Grid>
      <Grid item xs={12} md={5}>
        <Stack direction="row" spacing={4}>
          <OutLink sx={linkSx} href={`${documentHost}/en/index.html`}>
            Docs
          </OutLink>
          {isApp ? (
            <InLink sx={linkSx} href={`/app/${chainSetting.chainId}/lend/`}>
              Lend
            </InLink>
          ) : (
            ""
          )}
          {isApp ? (
            <InLink sx={linkSx} href={`/app/${chainSetting.chainId}/borrow/`}>
              Borrow
            </InLink>
          ) : (
            ""
          )}
        </Stack>
      </Grid>
      <Grid item xs={2} md={1}>
        <Stack direction="row" spacing={4}>
          <OutLink
            href="https://twitter.com/hb_poption"
            sx={{ color: "inherit" }}
          >
            <TwitterIcon />
          </OutLink>
          <OutLink
            href="https://github.com/Poption-DeFi"
            sx={{ color: "inherit" }}
          >
            <GitHubIcon />
          </OutLink>
          <OutLink
            href="https://discord.gg/wGeWbmTMu5"
            sx={{ color: "inherit" }}
          >
            <DiscordIcon />
          </OutLink>
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid
            item
            xs={6}
            md={6}
            sx={{ paddingLeft: "10px", textAlign: "right" }}
          >
            {isApp ? <WhichChain /> : ""}
          </Grid>
          <Grid item xs={6} md={6}>
            {isApp ? (
              <ChainWallet />
            ) : (
              <InLink
                href={appLink}
                sx={{
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <Button variant="outlined" sx={{ width: "100%" }}>
                  Launch App
                </Button>
              </InLink>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Header;
