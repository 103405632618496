import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "./slice";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { OutLink } from "../../utils/Link";
import chainSettings from "../../utils/chainConfig.json";
import { shortAddress } from "../../utils/tools";

const TransactionItem = ({ value }) => {
  console.log(value);
  const chainId = useSelector((state) => state.ethers.wallet.chainId);
  const chainInfo = chainSettings[chainId];
  const getScan = (address) =>
    chainInfo ? chainInfo.blockExplorerUrls[0] + "tx/" + address : address;

  return (
    <ListItem>
      <ListItemText
        primary={
          <Stack direction="row" spacing={2}>
            <Box sx={{ marginLeft: "1em" }}>
              {value.state === "fulfilled" ? (
                <DoneIcon color="success" />
              ) : value.state === "rejected" ? (
                <CancelIcon color="error" />
              ) : value.state === "pending" ? (
                <HourglassTopIcon color="secondary" />
              ) : (
                ""
              )}
            </Box>
            <Box sx={{ marginLeft: "1em" }}>{value.title}</Box>{" "}
          </Stack>
        }
        secondary={
          value.state === "fulfilled" ? (
            <Box sx={{ textAlign: "right" }}>
              <OutLink href={getScan(value.result.transactionHash)}>
                Transaction: {shortAddress(value.result.transactionHash)}
              </OutLink>
            </Box>
          ) : null
        }
      />
    </ListItem>
  );
};

export const TransactionDialog = (props) => {
  const transactions = useSelector((state) => state.transaction.transactions);
  const show = useSelector((state) => state.transaction.show);
  const dispatch = useDispatch();
  const sortedTransactions = _.reverse(
    _.sortBy(_.values(transactions), ["createTime"])
  );
  return (
    <Dialog
      onClose={() => {
        dispatch(actions.hide());
      }}
      open={show === "list"}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Transaction List</DialogTitle>
      <List sx={{ pt: 0 }}>
        {_.map(sortedTransactions, (value, key) => {
          return <TransactionItem value={value} key={key} />;
        })}
      </List>
    </Dialog>
  );
};

export default TransactionDialog;
