import React, { useContext, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { PositionDetailContext } from "context/PositionDetailContext";
import { PoolSqrtPriceContext } from "context/PoolSqrtPriceContext";
import { ERC20MetaContext } from "context/ERC20MetaContext";
import { useConsultant } from "context/ConsultantContext";
import { PageBox } from "utils/PageBox";
import Box from "@mui/material/Box";
import { useTitle } from "react-use";
import _ from "lodash";
import { TextField } from "@mui/material";
import {
  WithLiquidityLendingButton,
  WithPositionManagerButton,
} from "features/transaction/Buttons";
import { useChainSetting, useTokens } from "common/hooks";
import { mathTool } from "lp-lending-contract";
const { BF, TWO_F_64 } = mathTool;

const LendPosition = () => {
  const { tokenId } = useParams();
  const { liquidityLendingAddress } = useChainSetting();
  const [fetchPositionDetail, positions] = useContext(PositionDetailContext);
  const [fetchERC20, tokensMetadata] = useContext(ERC20MetaContext);
  const [fetchPrice, sqrtPrices] = useContext(PoolSqrtPriceContext);
  const { consultant } = useConsultant();

  const [liquidityAmount, setLiquidityAmount] = useState("");
  const [interestRate, setInterestRate] = useState("1");
  const [periodDay, setPeriodDay] = useState("");
  const position = useMemo(() => {
    const position = positions[tokenId];
    if (position) {
      const sqrtPriceLower = mathTool.tickToSqrtPrice(position.tickLower);
      const sqrtPriceUpper = mathTool.tickToSqrtPrice(position.tickUpper);
      const liquidity = BF(position.liquidity.toString());
      return { ...position, liquidity, sqrtPriceLower, sqrtPriceUpper };
    }
  }, [positions, tokenId]);
  const tokens = useTokens(position, tokensMetadata);
  const marginFactor = "0.1";
  const amounts = useMemo(() => {
    if (position) {
      const { liquidity, sqrtPriceLower, sqrtPriceUpper, fee } = position;
      const sqrtPrice_ = _.get(sqrtPrices, `${position.pool}.sqrtPrice`);
      if (sqrtPrice_) {
        const sqrtPrice = BF(sqrtPrice_);
        const price = sqrtPrice.pow(2);
        const ltoken0 = BF(1).div(sqrtPrice);
        const ltoken1 = BF(sqrtPrice);
        const margin0 = ltoken0
          .mul(marginFactor)
          .mul(fee)
          .div(1000000)
          .mul(BF(+interestRate).add(1));
        const margin1 = ltoken1
          .mul(marginFactor)
          .mul(fee)
          .div(1000000)
          .mul(BF(+interestRate).add(1));

        const margin0X64 = margin0.mul(TWO_F_64).toFixed(0);
        const margin1X64 = margin1.mul(TWO_F_64).toFixed(0);

        const vamount0 = ltoken0.mul(liquidity);
        const vamount1 = ltoken1.mul(liquidity);
        const [amount0, amount1] = mathTool.liqToAmount01(
          sqrtPrice,
          sqrtPriceLower,
          sqrtPriceUpper,
          liquidity
        );

        return {
          price,
          margin0,
          margin1,
          margin0X64,
          margin1X64,
          vamount0,
          vamount1,
          amount0,
          amount1,
        };
      }
    }
  }, [position, sqrtPrices, interestRate]);

  const period = Math.round(+periodDay * 86400);
  const interestRateX64 = BF(+interestRate || 0)
    .mul(TWO_F_64)
    .toFixed(0);

  useTitle("Poption Contribution - All Contribution Types");

  useEffect(() => {
    if (tokenId) {
      fetchPositionDetail(tokenId);
    }
  }, [tokenId, fetchPositionDetail]);

  useEffect(() => {
    if (position) {
      fetchPrice(position.pool);
      fetchERC20(position.token0);
      fetchERC20(position.token1);
    }
  }, [position, fetchERC20, fetchPrice]);

  if (!(position && tokens && amounts)) {
    return "loading";
  }
  const price_ = amounts.price.div(tokens.sqrtPriceScale.pow(2)).toNumber();

  return (
    <PageBox alpha={0.7}>
      <Box sx={{ typography: "h4" }}>Lend your Uniswap v3 position</Box>
      <p>
        Unlock the earning potential of your Uniswap v3 liquidity position by
        lending it to others.
      </p>
      <form>
        <p>
          Your position consists of {tokens.token0.symbol} as Token0 and{" "}
          {tokens.token0.symbol} as Token1.
        </p>
        <p>
          Lower price of the position:{" "}
          {position.sqrtPriceLower.div(tokens.sqrtPriceScale).pow(2).toNumber()}
          .
        </p>
        <p>
          Upper price of the position:{" "}
          {position.sqrtPriceUpper.div(tokens.sqrtPriceScale).pow(2).toNumber()}
          .
        </p>
        <p>Current price: {price_}.</p>
        <p>
          Virtually, the liquidity contains{" "}
          {amounts.vamount0.div(tokens.token0.scale).toNumber()}{" "}
          {tokens.token0.symbol} and{" "}
          {amounts.vamount1.div(tokens.token1.scale).toNumber()}{" "}
          {tokens.token1.symbol} (assuming a full range).
        </p>
        <p>
          In reality, the liquidity holds{" "}
          {amounts.amount0.div(tokens.token0.scale).toNumber()}{" "}
          {tokens.token0.symbol} and{" "}
          {amounts.amount1.div(tokens.token1.scale).toNumber()}{" "}
          {tokens.token1.symbol} within the current concentrated range.
        </p>{" "}
        <hr />
        <h4>Set amount of liquidity to lend</h4>
        <p>
          Your position has{" "}
          {position.liquidity.div(tokens.liquidityScale).toNumber()} units of
          liquidity. You can lend up to{" "}
          {position.liquidity
            .sub(mathTool.MIN_LIQUIDITY_KEEP)
            .div(tokens.liquidityScale)
            .toNumber()}{" "}
          units.
        </p>{" "}
        <TextField
          label="Liquidity Amount"
          value={liquidityAmount}
          onChange={(e) => setLiquidityAmount(e.target.value)}
          fullWidth
          margin="normal"
        />
        <h4>Set lending interest</h4>
        <p>
          The interest is calculated based on the fees collected by the
          position.
        </p>
        <p>
          For example, with an interest rate of {+interestRate}, if the fees
          collected are 1 {tokens.token0.symbol} and {price_}{" "}
          {tokens.token1.symbol}, the borrower must repay X{+interestRate + 1}{" "}
          times the fee amount, resulting in a total payment of{" "}
          {+interestRate + 1} {tokens.token0.symbol} and{" "}
          {price_ * (+interestRate + 1)} {tokens.token1.symbol}.
        </p>
        <TextField
          label="Interest Rate"
          value={interestRate}
          placeholder={"1"}
          onChange={(e) => setInterestRate(e.target.value)}
          fullWidth
          margin="normal"
        />
        <h4>Set margin</h4>
        <p>
          In LiquidityLending, set two margins, one for each token in the
          liquidity position. These margins serve as safety buffers for
          collecting interest payments.
        </p>
        <p>
          Given that the collateral will not decrease dramatically due to the
          liquidity pool position value being lower than the HOLD value, the key
          to setting the margins is considering potential interest and fees
          collected by the position.
        </p>
        <ul>
          <li>
            Margin for Token0: Set a safety buffer for the first token (
            {tokens.token0.symbol})
          </li>
          <li>
            Margin for Token1: Set a safety buffer for the second token (
            {tokens.token1.symbol})
          </li>
        </ul>
        <p>
          By setting appropriate margins for both tokens, ensure enough
          collateral to cover interest payments, safeguarding your lending
          position.
        </p>
        <p>
          In this MVP, margins are set automatically based on an assumed price
          change of 10 times up and down with a 2% change. The following
          calculation considers the fee generated by these price changes. The
          margin for each liquidity is: (virtual_amount_per_ liquidity) * 0.02 /
          2 * 10 * fee_rate * (1 + interest_rate).
        </p>
        <p>As a result</p>
        <ul>
          <li>
            Margin for Token0 ({tokens.token0.symbol}):{" "}
            {amounts.margin0
              .mul(tokens.liquidityScale)
              .div(tokens.token0.scale)
              .toNumber()}
          </li>
          <li>
            Margin for Token1 ({tokens.token1.symbol}):{" "}
            {amounts.margin1
              .mul(tokens.liquidityScale)
              .div(tokens.token1.scale)
              .toNumber()}
          </li>
        </ul>
        <h4>Set period</h4>
        <p>
          As a lender, you can determine the lending period for your liquidity
          position. When you revoke the lending offer, the borrower will be
          obligated to return the borrowed amount within the specified period.
          If the borrower fails to do so, the position can be liquidated.
        </p>
        <p>
          Keep in mind that liquidation does not occur automatically in the
          smart contract; it requires manual intervention. When setting the
          lending period, think about how long you're willing to lend your
          position and how long you can wait before leaving the market. This
          way, you can provide a suitable time frame for the borrower while
          considering your own financial needs and goals.
        </p>
        <TextField
          label={`Period Value (days) [= ${period} seconds]`}
          value={periodDay}
          onChange={(e) => setPeriodDay(e.target.value)}
          fullWidth
          margin="normal"
        />
        <p>The current referral is {consultant}.</p>
        <h4>Submit</h4>
        <p>Approve the usage of the liquidity token.</p>
        <WithPositionManagerButton
          variant="contained"
          color="primary"
          name="Approve"
          title={`Approve LiquidityLending to use Position ${tokenId}`}
          callContract={(contract) =>
            contract.approve(liquidityLendingAddress, tokenId)
          }
        >
          Approve
        </WithPositionManagerButton>
        <p>Create Lending Offer</p>
        <WithLiquidityLendingButton
          variant="contained"
          color="primary"
          name="Lend Position"
          title={`Lend Position ${tokenId} with ${liquidityAmount} `}
          callContract={(contract) => {
            console.log("contract", contract);
            return contract.lend(
              tokenId,
              mathTool
                .BF(+liquidityAmount)
                .mul(tokens.liquidityScale)
                .toFixed(0),
              interestRateX64,
              amounts.margin0X64,
              amounts.margin1X64,
              period,
              consultant
            );
          }}
        >
          Lend Position
        </WithLiquidityLendingButton>
      </form>
    </PageBox>
  );
};

export default LendPosition;
