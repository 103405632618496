import { configureStore } from "@reduxjs/toolkit";
import ethersReducer from "../common/slice";
import transaction from "../features/transaction/slice";

export const store = configureStore({
  reducer: {
    ethers: ethersReducer,
    transaction,
  },
});
