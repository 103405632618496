import React from "react";
import Grid from "@mui/material/Grid";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import { OutLink } from "../utils/Link";
import { DiscordIcon } from "../utils/Icons";

export const Footer = () => {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={2}
      sx={{ padding: "10px" }}
    >
      <Grid item md={0} lg={1} />
      <Grid item md={1} sx={{ textAlign: "center" }}>
        <OutLink
          href="https://twitter.com/hb_poption"
          sx={{ color: "inherit" }}
        >
          <TwitterIcon />
        </OutLink>
      </Grid>
      <Grid item md={1} sx={{ textAlign: "center" }}>
        <OutLink
          href="https://github.com/Poption-DeFi"
          sx={{ color: "inherit" }}
        >
          <GitHubIcon />
        </OutLink>
      </Grid>
      <Grid item md={1} sx={{ textAlign: "center" }}>
        <OutLink href="https://discord.gg/wGeWbmTMu5" sx={{ color: "inherit" }}>
          <DiscordIcon />
        </OutLink>
      </Grid>
      <Grid item md={0} lg={1} />
    </Grid>
  );
};
export default Footer;
