import { useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import chainSettings from "./chainSettings";
import { mathTool } from "lp-lending-contract";

export function useChainSetting() {
  const { chainId } = useParams();
  return useMemo(() => {
    return _.find(
      chainSettings,
      (chainSetting) => chainSetting.chainId === +chainId
    );
  }, [chainId]);
}
export const prepareTokens = (pair, tokensMetadata) => {
  if (pair) {
    const token0 = tokensMetadata[pair.token0];
    const token1 = tokensMetadata[pair.token1];
    if (token0 && token1) {
      return {
        token0: {
          ...token0,
          scale: mathTool.BigFloat.pow(10, token0.decimals),
        },
        token1: {
          ...token1,
          scale: mathTool.BigFloat.pow(10, token1.decimals),
        },
        sqrtPriceScale: mathTool.BigFloat.pow(
          10,
          token1.decimals - token0.decimals
        ).sqrt(),
        liquidityScale: mathTool.BigFloat.pow(
          10,
          token1.decimals + token0.decimals
        ).sqrt(),
        liquidityDecimals: Math.ceil((token1.decimals + token0.decimals) / 2),
      };
    }
  }
};

export const useTokens = (pair, tokensMetadata) => {
  return useMemo(
    () => prepareTokens(pair, tokensMetadata),
    [pair, tokensMetadata]
  );
};
