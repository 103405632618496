import _ from "lodash";

export function generateGet() {
  const store = {};
  return (func, index) => {
    if (_.has(store, index)) {
      return _.get(store, index);
    } else {
      const res = func();
      _.set(store, index, res);
      return res;
    }
  };
}

export function generateGetAsync() {
  const store = {};
  return async (func, index) => {
    if (_.has(store, index)) {
      return _.get(store, index);
    } else {
      const res = await func();
      _.set(store, index, res);
      return res;
    }
  };
}
