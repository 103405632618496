export const chainSettings = [
  {
    chainId: 421613,
    chainName: "Arbitrum test",
    liquidityLendingAddress: "0x39b0f74B0D3Cd0b6C6F20740b0061B38ac3DE4b0",
    positionManagerAddress: "0x622e4726a167799826d1E1D150b076A7725f5D81",
    poolFactoryAddress: "0x4893376342d5D7b3e31d4184c08b265e5aB2A3f6",
    scanHost: "https://api-goerli.arbiscan.io",
    scanApiKey: "R4WGTEAVYZWHCGQ7I42URTCR5TKBKBWKBW",
    testPool: [
      "0x46c91589a6b535018B3688A1C511132235A25AF5",
      "0xe0f4f60d12d87666AB76D94e89A146e4afc294e6",
      3000,
    ],
  },
  {
    chainId: 44787,
    chainName: "Celo test",
    liquidityLendingAddress: "0x498Cf3DCD7F581e8ce2d5fd84c7D04A8B6c2C500",
    positionManagerAddress: "0x3d79EdAaBC0EaB6F08ED885C05Fc0B014290D95A",
    poolFactoryAddress: "0xAfE208a311B21f13EF87E33A90049fC17A7acDEc",
    scanHost: "https://api-alfajores.celoscan.io",
    scanApiKey: "JCZG2WWSWAPD8V34EDW2PBA7581CWY9KIW",
    testPool: [
      "0x46c91589a6b535018B3688A1C511132235A25AF5",
      "0xe0f4f60d12d87666AB76D94e89A146e4afc294e6",
      3000,
    ],
  },
  {
    chainId: 137,
    chainName: "Polygon",
    liquidityLendingAddress: "0xD20a388bbB36fd0e9B1f32A74f0eED5616e0ac76",
    positionManagerAddress: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
    poolFactoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    scanHost: "https://api.polygonscan.com",
    scanApiKey: "NP2DIFWJWIY4TPN4C82SSY4QG5ECXMC8FS",
    testPool: [
      "0x9873293D84AB0E2Abfd9966da41c8ca479ac202A",
      "0x266F944C3B4baE0DDC84dD1704AbE4448B4dD17B",
      10000,
    ],
  },
  {
    chainId: 42161,
    chainName: "Arbitrum",
    liquidityLendingAddress: "0x2Fea428C03e83514bbfc5EbA4Aa08B34F19F2fE7",
    positionManagerAddress: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
    poolFactoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    scanHost: "https://api.arbiscan.io",
    scanApiKey: "R4WGTEAVYZWHCGQ7I42URTCR5TKBKBWKBW",
    testPool: [
      "0x39b0f74B0D3Cd0b6C6F20740b0061B38ac3DE4b0",
      "0x3DeAC2c8Dbe7D75D53D33d23d49E1336f22d9420",
      3000,
    ],
  },
];

export const defaultChain = 421613;

export const documentHost = "https://doc.liquiditylending.poption.org";
export default chainSettings;
