import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { actions } from "./slice";

export const TransactionButton = () => {
  const { pending, fulfilled, rejected } = useSelector(
    (state) => state.transaction.counter
  );
  const dispatch = useDispatch();
  return (
    <Button
      sx={{ width: "100%", textTransform: "none" }}
      onClick={() => {
        dispatch(actions.show("list"));
      }}
    >
      {fulfilled > 0 ? (
        <Badge badgeContent={fulfilled} color="success">
          <DoneIcon color="action" />
        </Badge>
      ) : (
        ""
      )}
      {pending > 0 ? (
        <Badge badgeContent={pending} color="secondary">
          <HourglassTopIcon color="action" />
        </Badge>
      ) : (
        ""
      )}
      {rejected > 0 ? (
        <Badge badgeContent={rejected} color="error">
          <CancelIcon color="action" />
        </Badge>
      ) : (
        ""
      )}
      {"TXN"}
    </Button>
  );
};
