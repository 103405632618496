import React, { useEffect } from "react";
import Link from "@mui/material/Link";
import { useHref } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

export const useForceTrailingSlash = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.endsWith("/")) {
      navigate(`${location.pathname}/`, { replace: true });
    }
  }, [location, navigate]);
};

export const InLink = (props) => {
  const navigate = useNavigate();
  const link = useHref(props.href);
  return (
    <Link
      {...props}
      onClick={(event) => {
        event.preventDefault();
        navigate(link);
      }}
    />
  );
};

export const OutLink = (props) => {
  return <Link {...props} target="_blank" rel="noopener" />;
};
