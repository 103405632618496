import React, { useState, useContext, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LendingOfferContext } from "context/LendingOfferContext";
import { ERC20MetaContext } from "context/ERC20MetaContext";
import { PageBox } from "utils/PageBox";
import Box from "@mui/material/Box";
import { useTitle } from "react-use";
import { TextField } from "@mui/material";
import {
  WithLiquidityLendingButton,
  WithERC20MetadataButton,
} from "features/transaction/Buttons";
import { useChainSetting, useTokens } from "common/hooks";
import { mathTool } from "lp-lending-contract";
import { constants } from "ethers";
import { useConsultant } from "context/ConsultantContext";
const { BF, TWO_F_64 } = mathTool;

const BorrowLendingOffer = () => {
  const { lendingOfferId } = useParams();
  const { liquidityLendingAddress } = useChainSetting();
  const [fetchERC20, tokensMetadata] = useContext(ERC20MetaContext);
  const [liquidityAmount, setLiquidityAmount] = useState("0");
  const [marginFactor, setMarginFactor] = useState("1.5");
  const { consultant } = useConsultant();
  const [fetchLendingOffer, lendingOffers] = useContext(LendingOfferContext);
  const lendingOffer = useMemo(
    () => lendingOffers[lendingOfferId],
    [lendingOffers, lendingOfferId]
  );
  const tokens = useTokens(lendingOffer, tokensMetadata);

  useEffect(() => {
    if (lendingOfferId) {
      fetchLendingOffer(lendingOfferId);
    }
  }, [lendingOfferId, fetchLendingOffer]);

  useEffect(() => {
    if (lendingOffer) {
      fetchERC20(lendingOffer.token0);
      fetchERC20(lendingOffer.token1);
    }
  }, [lendingOffer, fetchERC20]);

  useTitle("Poption Contribution - Borrow LendingOffer");

  if (!(lendingOffer && tokens)) {
    return "loading";
  }
  const minMargin0 = BF(+liquidityAmount)
    .mul(tokens.liquidityScale)
    .mul(lendingOffer.margin0X64.toString())
    .div(mathTool.TWO_F_64)
    .ceil()
    .div(tokens.token0.scale)
    .toFixed(tokens.token0.decimals);

  const minMargin1 = BF(+liquidityAmount)
    .mul(tokens.liquidityScale)
    .mul(lendingOffer.margin1X64.toString())
    .div(mathTool.TWO_F_64)
    .ceil()
    .div(tokens.token1.scale)
    .toFixed(tokens.token1.decimals);

  const priceLower = BF(lendingOffer.sqrtPriceLowerX96)
    .div(mathTool.TWO_F_96)
    .div(tokens.sqrtPriceScale)
    .pow(2)
    .toNumber();
  const priceUpper = BF(lendingOffer.sqrtPriceUpperX96)
    .div(mathTool.TWO_F_96)
    .div(tokens.sqrtPriceScale)
    .pow(2)
    .toNumber();

  return (
    <PageBox>
      <Box sx={{ typography: "h4" }}>Borrow liquidity</Box>
      <p>
        Here, you can borrow liquidity from the lending offer , taking advantage
        of long impermanent loss or long volatility opportunities, and
        addressing other financial needs.
      </p>
      <p>Lending Offer ID: {lendingOfferId}</p>
      <p>Uniswap Position Token ID: {lendingOffer.tokenId.toString()}</p>
      <p>
        Token0: {tokens.token0.symbol} ({tokens.token0.name})
      </p>
      <p>
        Token1: {tokens.token1.symbol} ({tokens.token1.name})
      </p>
      <p>
        Interest Rate:{" "}
        {BF(lendingOffer.interestRateX64).div(TWO_F_64).mul(100).toNumber()}%
      </p>
      <p>
        Price Lower: 1 {tokens.token0.symbol} = {priceLower}{" "}
        {tokens.token1.symbol}
      </p>
      <p>
        Price Upper: 1 {tokens.token0.symbol} = {priceUpper}{" "}
        {tokens.token1.symbol}
      </p>
      <form>
        <h4>Select the liquidity amount to borrow</h4>
        <p>
          Borrow from the lending offer with the desired liquidity amount. The
          current available liquidity in the offer is{" "}
          {BF(lendingOffer.freeLiquidityAmount.toString())
            .div(tokens.liquidityScale)
            .toFixed(tokens.liquidityDecimals)}
          .
        </p>{" "}
        <TextField
          label="Liquidity Amount"
          value={liquidityAmount}
          onChange={(e) => setLiquidityAmount(e.target.value)}
          fullWidth
          margin="normal"
        />
        <h4>Set extra collateral for Tokens</h4>
        <p>
          Provide an appropriate extra collateral for both tokens in the
          liquidity position to fulfill the margin requirement. The extra
          collateral acts as a safety buffer and will be used to secure your
          borrowing. The minimum required collateral for Token0 is {minMargin0}.
          The minimum required collateral for Token1 is {minMargin1}. The extra
          collateral you provide must be greater than the minimum required.
        </p>
        <p>
          The collateral factor is a multiplier applied to the minimum required
          collateral to determine the extra collateral you provide. It allows
          you to adjust the amount of extra collateral based on your risk
          appetite. The collateral factor must be greater than 1.
        </p>
        <TextField
          label="Collateral Factor"
          value={marginFactor}
          onChange={(e) => setMarginFactor(e.target.value)}
          fullWidth
          margin="normal"
        />
        <ul>
          <li>
            The extra collateral for token0 ({tokens.token0.symbol}) is{" "}
            {BF(+marginFactor).mul(minMargin0).toFixed(tokens.token0.decimals)}.
          </li>
          <li>
            The extra collateral for token1 ({tokens.token1.symbol}) is{" "}
            {BF(+marginFactor).mul(minMargin1).toFixed(tokens.token1.decimals)}.
          </li>
        </ul>
        <h4>Deadline</h4>
        <p>
          The deadline for this borrowing is set to 1 minute later. The exact
          timestamp is {Math.round(+new Date() / 1000) + 60}.
        </p>
        <h4>Reference</h4>
        <p>
          Provide any necessary reference information for this borrowing. The
          current consultant is {consultant}.
        </p>
        <h4>Approve Token Usage</h4>
        <p>
          Grant approval for the usage of both Token0 and Token1 in your
          liquidity position. If you have already approved that, please proceed
          to the next step.
        </p>
        <WithERC20MetadataButton
          address={lendingOffer.token0}
          variant="contained"
          color="primary"
          name={`Approve ${tokens.token0.symbol} usage.`}
          title={`Approve ${tokens.token0.symbol} usage.`}
          callContract={(contract) =>
            contract.approve(liquidityLendingAddress, constants.MaxUint256)
          }
        >
          Approve {tokens.token0.symbol} Usage
        </WithERC20MetadataButton>
        <WithERC20MetadataButton
          address={lendingOffer.token1}
          variant="contained"
          color="primary"
          name={`Approve ${tokens.token1.symbol} usage.`}
          title={`Approve ${tokens.token1.symbol} usage.`}
          callContract={(contract) =>
            contract.approve(liquidityLendingAddress, constants.MaxUint256)
          }
        >
          Approve {tokens.token1.symbol} Usage
        </WithERC20MetadataButton>
        <h4>Borrow LendingOffer</h4>
        <p>
          Once you have completed the above steps, click the button below to
          borrow the selected lendingOffer. Make sure you have provided the
          correct details and approved the token usage before proceeding.
        </p>
        <WithLiquidityLendingButton
          variant="contained"
          color="primary"
          name="Borrow LendingOffer"
          title={`Borrow LendingOffer with Lending Offer ID ${lendingOfferId}`}
          callContract={(contract) =>
            contract.borrow(
              lendingOfferId,
              BF(liquidityAmount).mul(tokens.liquidityScale).toFixed(0),
              BF(marginFactor)
                .mul(minMargin0)
                .mul(tokens.token0.scale)
                .toFixed(0),
              BF(marginFactor)
                .mul(minMargin1)
                .mul(tokens.token1.scale)
                .toFixed(0),
              Math.round(+new Date() / 1000) + 60,
              consultant
            )
          }
        >
          Borrow LendingOffer
        </WithLiquidityLendingButton>
      </form>
    </PageBox>
  );
};

export default BorrowLendingOffer;
