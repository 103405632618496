import React, { useContext, useEffect } from "react";
import {
  LendingOfferListContext,
  LendingOfferListProvider,
} from "context/LendingOfferListContext";
import { ERC20MetaContext } from "context/ERC20MetaContext";
import { PageBox } from "utils/PageBox";
import Box from "@mui/material/Box";
import { useTitle } from "react-use";
import _ from "lodash";
import { useTable } from "react-table";
import { InLink, useForceTrailingSlash } from "utils/Link";
import { mathTool } from "lp-lending-contract";
import { prepareTokens } from "common/hooks";
import { shortAddress } from "utils/tools";

const { BF, TWO_F_64, TWO_F_96 } = mathTool;

const LendingOfferTable = ({ lendingOffers, tokensMetadata }) => {
  // Process the data
  const data = React.useMemo(() => {
    return Object.entries(lendingOffers).map(([tokenId, lendingOffer]) => {
      const tokens = prepareTokens(lendingOffer, tokensMetadata);
      if (tokens) {
        return {
          lendingOfferId: lendingOffer.lendingOfferId,
          tokenId: lendingOffer.tokenId.toString(),
          token0: `${tokens.token0.name} (${tokens.token0.symbol})`,
          token1: `${tokens.token1.name} (${tokens.token1.symbol})`,
          interestRate: `${BF(lendingOffer.interestRateX64)
            .div(TWO_F_64)
            .mul(100)
            .toNumber()}%`,
          priceLower: BF(lendingOffer.sqrtPriceLowerX96)
            .div(TWO_F_96)
            .div(tokens.sqrtPriceScale)
            .pow(2)
            .toNumber(),
          priceUpper: BF(lendingOffer.sqrtPriceUpperX96)
            .div(TWO_F_96)
            .div(tokens.sqrtPriceScale)
            .pow(2)
            .toNumber(),
          liquidity: BF(lendingOffer.freeLiquidityAmount)
            .div(tokens.liquidityScale)
            .toNumber(),
        };
      } else {
        return {
          lendingOfferId: lendingOffer.lendingOfferId,
          tokenId: lendingOffer.tokenId.toString(),
          token0: shortAddress(lendingOffer.token0),
          token1: shortAddress(lendingOffer.token1),
          interestRate: `${BF(lendingOffer.interestRateX64)
            .div(TWO_F_64)
            .mul(100)
            .toNumber()}%`,
          priceLower: BF(lendingOffer.sqrtPriceLowerX96)
            .div(TWO_F_96)
            .pow(2)
            .toNumber(),
          priceUpper: BF(lendingOffer.sqrtPriceUpperX96)
            .div(TWO_F_96)
            .pow(2)
            .toNumber(),
          liquidity: BF(lendingOffer.freeLiquidityAmount).toNumber(),
        };
      }
    });
  }, [lendingOffers, tokensMetadata]);

  // Define table columns
  const columns = React.useMemo(
    () => [
      { Header: "LendingOffer ID", accessor: "lendingOfferId" },
      { Header: "Position ID", accessor: "tokenId" },
      { Header: "Token 0", accessor: "token0" },
      { Header: "Token 1", accessor: "token1" },
      { Header: "Interest Rate", accessor: "interestRate" },
      { Header: "Min Price", accessor: "priceLower" },
      { Header: "Max Price", accessor: "priceUpper" },
      { Header: "Liquidity", accessor: "liquidity" },
      {
        Header: "Lend",
        id: "lend",
        accessor: "lendingOfferId",
        Cell: ({ cell: { value } }) => (
          <InLink href={`${value}`}>Borrow LendingOffer</InLink>
        ),
      },
    ],
    []
  );

  // Set up the table instance
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  // Render the table
  return (
    <table {...getTableProps()} style={{ border: "solid 1px black" }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{
                  borderBottom: "solid 1px black",
                  background: "aliceblue",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: "10px",
                      border: "solid 1px gray",
                      background: "papayawhip",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const LendingOfferList = () => {
  useForceTrailingSlash();
  return (
    <LendingOfferListProvider>
      <LendingOfferListInner />
    </LendingOfferListProvider>
  );
};

export const LendingOfferListInner = () => {
  const lendingOffers = useContext(LendingOfferListContext);
  const [fetchERC20, tokensMetadata] = useContext(ERC20MetaContext);

  useTitle("Poption Contribution - All Contribution Types");

  useEffect(() => {
    _.map(lendingOffers, (lendingOffer) => {
      if (lendingOffer) {
        fetchERC20(lendingOffer.token0);
        fetchERC20(lendingOffer.token1);
      }
    });
  }, [lendingOffers, fetchERC20]);
  return (
    <PageBox>
      <Box sx={{ typography: "h4" }}>Explore Available Lending Offers</Box>
      <p>
        Discover a variety of lending offers featuring Uniswap v3 positions with
        test tokens on our platform. Browse, evaluate, and borrow from these
        offers to fulfill your financial needs while participating in a
        risk-free, test environment.
      </p>
      <p>
        Dive into the world of DeFi with confidence, knowing that you're working
        with test tokens to gain valuable experience. Happy exploring and
        borrowing!
      </p>
      {lendingOffers ? (
        <LendingOfferTable
          lendingOffers={lendingOffers}
          tokensMetadata={tokensMetadata}
        />
      ) : (
        "Loading..."
      )}
    </PageBox>
  );
};

export default LendingOfferList;
