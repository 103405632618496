import { ethers } from "ethers";
import { generateGet } from "./setterStore";
import chainSettings from "../utils/chainConfig.json";

const providers = generateGet();

export const getProvider = (chainId) => {
  return providers(() => {
    const rpc = chainSettings[chainId].rpcUrls[0];
    return new ethers.providers.JsonRpcBatchProvider({
      url: rpc,
    });
  }, [chainId]);
};
