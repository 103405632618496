import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { useChainSetting } from "common/hooks";
import { useSelector } from "react-redux";
import _ from "lodash";
import { addressToTopic, createFetchEventsBatch, fetchAll } from "utils/event";
import { PositionDetailContext } from "context/PositionDetailContext";

export const MyPositionContext = createContext();

const useMyPositions = () => {
  const userAddress = useSelector((state) =>
    _.get(state, "ethers.wallet.data.address")
  );
  const { positionManagerAddress, scanHost, scanApiKey } = useChainSetting();
  const [events, setEvents] = useState();

  const fetchEvents = useCallback(async () => {
    try {
      const url =
        `${scanHost}/api?module=logs&action=getLogs&fromBlock={fromBlock}&toBlock=latest&address=${positionManagerAddress}` +
        "&topic0=0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef" +
        `&topic2=${addressToTopic(userAddress)}` +
        `&apikey=${scanApiKey}`;

      const fetchEventsBatch = createFetchEventsBatch(url, (event) => {
        return {
          tokenId: parseInt(event.topics[3], 16),
        };
      });
      await fetchAll(fetchEventsBatch, setEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }, [positionManagerAddress, userAddress, scanHost, scanApiKey]);

  useEffect(() => {
    if (userAddress) {
      fetchEvents();
    }
  }, [fetchEvents, positionManagerAddress, userAddress]);

  return events;
};

const usePositionsDetail = () => {
  const userAddress = useSelector((state) =>
    _.get(state, "ethers.wallet.data.address")
  );
  const events = useMyPositions();

  const [fetchPositionDetail, positionDetails] = useContext(
    PositionDetailContext
  );

  useEffect(() => {
    _.map(events, (event) => {
      fetchPositionDetail(event.tokenId);
    });
  }, [events, fetchPositionDetail]);

  const positions = useMemo(() => {
    return _.reduce(
      _.filter(
        _.map(events, (event) => {
          return { ...positionDetails[event.tokenId], tokenId: event.tokenId };
        }),
        (position) => position && position.ownerAddress === userAddress
      ),
      (result, position) => {
        result[position.tokenId] = position;
        return result;
      },
      {}
    );
  }, [events, positionDetails, userAddress]);
  return events ? positions : null;
};

export const MyPositionProvider = ({ children }) => {
  const positions = usePositionsDetail();
  return (
    <MyPositionContext.Provider value={positions}>
      {children}
    </MyPositionContext.Provider>
  );
};
