// SPDX-License-Identifier: BUSL-1.1
/*
 * Copyright ©2022 by Poption.
 * Author: Hydrogenbear <hydrogenbear@poption.org>
 */

const Decimal = require("decimal.js");
const _ = require("lodash");

const { BigNumber } = require("ethers");

const BigFloat = Decimal.clone({
  precision: 150,
  toExpNeg: -150,
  toExpPos: 150,
});

const BF = (x) => new BigFloat(x.toString());
const TWO_F_256 = BF(2).pow(256);
const TWO_MAX_U160 = BF(2).pow(160).sub(1);
const TWO_F_192 = new BigFloat(
  "6277101735386680763835789423207666416102355444464034512896"
);
const TWO_F_128 = new BigFloat("340282366920938463463374607431768211456");
const TWO_F_96 = new BigFloat("79228162514264337593543950336");
const TWO_F_64 = new BigFloat("18446744073709551616");
const TWO_I_128 = BigNumber.from("340282366920938463463374607431768211456");
const TWO_I_64 = BigNumber.from("18446744073709551616");

const toDec = (x) => BF(x.toString()).div(TWO_F_64);
const toInt = (x) => BF(x).mul(TWO_F_64).toFixed(0);

const readGas = async (trx) => {
  const receipt = await trx.wait();
  console.log(`gas: ${receipt.gasUsed}`);
};

const estGas = async (trx) => {
  const gas = await trx;
  console.log(`gas: ${gas}`);
};

const getRandomUaXb = (a, b) => {
  const w = BigFloat.pow(2, a).sub(1).ln();
  const base = BigFloat.pow(2, b);
  return () => {
    const a = BigFloat.random().mul(w);
    return BigFloat.div(a.exp().floor().sub(1), base);
  };
};

const getRandomIaXb = (a, b) => {
  const w = BigFloat.pow(2, a - 1)
    .sub(1)
    .ln();
  const base = BigFloat.pow(2, b);
  return () => {
    const a = BigFloat.random().mul(w);
    return BigFloat.div(
      a
        .exp()
        .floor()
        .sub(1)
        .mul(Number(Math.random() > 0.5) * 2 - 1),
      base
    );
  };
};

const getRandomU128X64 = getRandomUaXb(128, 64);
const getRandomU256X64 = getRandomUaXb(256, 64);
const getRandomU160X96 = getRandomUaXb(160, 96);
const getRandomU128 = getRandomUaXb(128, 0);
const getRandomU256 = getRandomUaXb(256, 0);
const getRandomI256 = getRandomIaXb(256, 0);

const getRandomU160X96LH = () => {
  const a = getRandomU160X96();
  const b = getRandomU160X96();
  if (a.lt(b)) {
    return [a, b];
  } else {
    return [b, a];
  }
};

const amount0 = (l, h, liq) => liq.mul(h.sub(l)).div(h).div(l);
const amount1 = (l, h, liq) => liq.mul(h.sub(l));

const liqToAmount01 = (c, l, h, liq) => {
  if (c.lte(l)) {
    return [amount0(l, h, liq), BF(0)];
  } else if (c.gte(h)) {
    return [BF(0), amount1(l, h, liq)];
  } else {
    return [amount0(c, h, liq), amount1(l, c, liq)];
  }
};

const tickToSqrtPrice = (tick) => {
  return BF("1.0001").pow(tick.toString()).sqrt();
};

module.exports = {
  BigFloat,
  BF,
  TWO_I_64,
  TWO_I_128,
  TWO_F_64,
  C_TOFIX_64: 30,
  TWO_F_128,
  TWO_F_256,
  TWO_F_192,
  TWO_F_96,
  MIN_LIQUIDITY_KEEP: 0x1000,
  TWO_MAX_U160,
  toDec,
  toInt,
  readGas,
  estGas,
  getRandomUaXb,
  getRandomIaXb,
  getRandomU128,
  getRandomU128X64,
  getRandomU160X96,
  getRandomU160X96LH,
  getRandomU256X64,
  getRandomU256,
  getRandomI256,
  liqToAmount01,
  amount0,
  amount1,
  tickToSqrtPrice,
};
