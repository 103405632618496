export const selectedSx = {
  color: "text.primary",
  fontWeight: "bold",
};
export const unSelectedSx = {
  color: "text.secondary",
};

export const bgColor = "#cff3cfbd";

export const linkSx = {
  color: "inherit",
  textDecoration: "none",
  "&:hover": { textDecoration: "underline" },
};

export const stateToDict = ([get, set]) => {
  return { get, set };
};

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const timeDeltaToString = (timeDelta) => {
  if (isNaN(timeDelta)) {
    return "";
  }

  return (
    (timeDelta > 0 ? "+" : "-") +
    `${Math.floor(Math.abs(timeDelta) / (24 * 60 * 60))}d${new Date(
      Math.abs(timeDelta) * 1000
    )
      .toISOString()
      .substr(11, 8)}`
  );
};

export const getSlots = (slots, gapScale) => {
  const gap = slots[1] - slots[0];
  return [+slots[0] - gap * gapScale].concat(slots, [
    +slots[slots.length - 1] + gap * gapScale,
  ]);
};

export const geneData = (slots, margin, step, getItem, head, body, tail) => {
  const data = [];
  for (let x = slots[0] - margin; x < slots[0]; x += step) {
    data.push(getItem(x, head));
  }
  for (let i = 1; i < slots.length; i++) {
    const l = slots[i - 1];
    const h = slots[i];
    for (let x = l; x < h; x += step) {
      data.push(getItem(x, body(x, l, h, i)));
    }
  }
  for (
    let x = slots[slots.length - 1];
    x < slots[slots.length - 1] + margin;
    x += step
  ) {
    data.push(getItem(x, tail));
  }
  return data;
};

export const shortAddress = (str) => {
  return str.slice(0, 8) + "..." + str.slice(-4);
};

export const marginStep = (slots, marginScale, stepScale) => {
  marginScale = marginScale || 2;
  stepScale = stepScale || 0.02;
  const base = (slots[slots.length - 1] - slots[0]) / (slots.length - 1);
  return {
    margin: base * marginScale,
    step: base * stepScale,
    gap: +base.toPrecision(8),
  };
};
