import { createContext, useState, useMemo } from "react";
import { getProvider } from "common/providers";
import { ethers } from "ethers";
import { artifacts, mathTool } from "lp-lending-contract";
import moize from "moize";
import { useChainSetting } from "common/hooks";
import _ from "lodash";

export const PoolSqrtPriceContext = createContext();

const usePoolSqrtPricedata = () => {
  const { chainId } = useChainSetting();
  const [sqrtPrice, setSqrtPrice] = useState({});

  const fetchSqrtPrice = useMemo(() => {
    const provider = getProvider(chainId);
    return moize.promise(
      async (poolAddress) => {
        const pool = new ethers.Contract(
          poolAddress,
          artifacts.IUniswapV3Pool.abi,
          provider
        );
        console.log("fetchPosition", poolAddress);
        const slot0 = await pool.slot0();
        const res = {
          ..._.pick(slot0, ["sqrtPriceX96", "tick"]),
          sqrtPrice: mathTool
            .BF(slot0.sqrtPriceX96.toString())
            .div(mathTool.TWO_F_96)
            .toFixed(mathTool.C_TOFIX_64),
        };
        setSqrtPrice((oldSqrtPrice) => {
          return {
            ...oldSqrtPrice,
            [poolAddress]: res,
          };
        });
        return res;
      },
      {
        transformArgs: (args) => {
          const key = args[0].toString();
          return key;
        },
        maxSize: 100,
        maxAge: 1000 * 30,
      }
    );
  }, [chainId]);
  return [fetchSqrtPrice, sqrtPrice];
};

export const PoolSqrtPriceProvider = ({ children }) => {
  const data = usePoolSqrtPricedata();
  return (
    <PoolSqrtPriceContext.Provider value={data}>
      {children}
    </PoolSqrtPriceContext.Provider>
  );
};
