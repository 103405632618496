import React from "react";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "./slice";
import Paper from "@mui/material/Paper";

const translateMessage = (message) => {
  if (
    message.indexOf(
      'reason="execution reverted: ERC721: token already minted"'
    ) >= 0
  ) {
    return "Cannot mint the token. Token already minted.";
  } else {
    return message;
  }
};

export const ErrorDialog = (props) => {
  const error = useSelector((state) => state.transaction.error);
  const dispatch = useDispatch();
  return (
    <Dialog
      onClose={() => {
        dispatch(actions.closeError());
      }}
      open={!!error}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Transaction Error</DialogTitle>
      <Paper sx={{ padding: "1em" }}>
        {error ? translateMessage(error.message) : ""}
      </Paper>
    </Dialog>
  );
};

export default ErrorDialog;
