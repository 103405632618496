import { createContext, useState, useMemo } from "react";
import { getProvider } from "common/providers";
import { ethers } from "ethers";
import { artifacts } from "lp-lending-contract";
import moize from "moize";
import { useParams } from "react-router-dom";

export const ERC20MetaContext = createContext();

const useERC20Metadata = () => {
  const { chainId } = useParams();
  const [tokens, setTokens] = useState({});

  const fetchERC20 = useMemo(() => {
    return moize.promise(
      async (erc20Address) => {
        console.log("fetchERC20", erc20Address);
        const provider = getProvider(chainId);
        const erc20 = new ethers.Contract(
          erc20Address,
          artifacts.IERC20Metadata.abi,
          provider
        );
        const [symbol, name, decimals] = await Promise.all([
          erc20.symbol(),
          erc20.name(),
          erc20.decimals(),
        ]);
        const res = { symbol, name, decimals };
        setTokens((oldTokens) => {
          return {
            ...oldTokens,
            [erc20Address]: res,
          };
        });
        return res;
      },
      {
        transformArgs: (args) => {
          const key = args[0].toString();
          return key;
        },
        maxSize: 100,
      }
    );
  }, [chainId]);
  return [fetchERC20, tokens];
};

export const ERC20MetaProvider = ({ children }) => {
  const [fetchERC20, tokensMetadata] = useERC20Metadata();
  return (
    <ERC20MetaContext.Provider value={[fetchERC20, tokensMetadata]}>
      {children}
    </ERC20MetaContext.Provider>
  );
};
