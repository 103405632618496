import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { useChainSetting } from "common/hooks";
import _ from "lodash";
import { createFetchEventsBatch, fetchAll } from "utils/event";
import { LendingOfferContext } from "context/LendingOfferContext";

export const LendingOfferListContext = createContext();

const useLendingOfferEvents = () => {
  const { liquidityLendingAddress, scanHost, scanApiKey } = useChainSetting();
  const [events, setEvents] = useState();

  const fetchEvents = useCallback(async () => {
    try {
      const url =
        `${scanHost}/api?module=logs&action=getLogs&fromBlock={fromBlock}` +
        `&toBlock=latest&address=${liquidityLendingAddress}` +
        "&topic0=0x50f3ecdb69ff69ffc511eeceb603ce7e745d4a0d6abac13e9a8818b71f0c5bb2" +
        `&apikey=${scanApiKey}`;

      const fetchEventsBatch = createFetchEventsBatch(url, (event) => {
        return {
          tokenId: parseInt(event.data, 16),
        };
      });
      await fetchAll(fetchEventsBatch, setEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }, [liquidityLendingAddress, scanHost, scanApiKey]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents, liquidityLendingAddress]);

  return events;
};

const useLendingOfferList = () => {
  const events = useLendingOfferEvents();

  const [fetchLendingOffer, lendingOffers] = useContext(LendingOfferContext);

  useEffect(() => {
    _.map(events, (event) => {
      fetchLendingOffer(event.tokenId);
    });
  }, [events, fetchLendingOffer]);

  const lendingOfferlist = useMemo(() => {
    return _.reduce(
      _.filter(
        _.map(events, (event) => {
          return {
            ...lendingOffers[event.tokenId],
            lendingOfferId: event.tokenId,
          };
        }),
        (lendingOffers) => lendingOffers.tokenId
      ),
      (result, lendingOffer) => {
        result[lendingOffer.lendingOfferId] = lendingOffer;
        return result;
      },
      {}
    );
  }, [events, lendingOffers]);
  return events ? lendingOfferlist : null;
};

export const LendingOfferListProvider = ({ children }) => {
  const lendingOfferList = useLendingOfferList();
  return (
    <LendingOfferListContext.Provider value={lendingOfferList}>
      {children}
    </LendingOfferListContext.Provider>
  );
};
