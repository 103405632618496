import format from "string-format";
import axios from "axios";
import _ from "lodash";

export const createFetchEventsBatch = (url, parseData) => {
  return async (fromBlock) => {
    const response = await axios.get(format(url, { fromBlock }));
    if (response.data.status === "0") {
      return [];
    } else if (response.data.status !== "1") {
      throw Error(`${response.data.status}: ${response.data.message}`);
    }
    const rawEvents = response.data.result;

    const parsedEvents = rawEvents.map((event) => ({
      ...event,
      blockNumber: parseInt(event.blockNumber, 16),
      transactionIndex: parseInt(event.transactionIndex, 16),
      logIndex: parseInt(event.logIndex, 16),
      ...parseData(event),
    }));
    return parsedEvents;
  };
};

export const fetchAll = async (fetchEventsBatch, setEvents) => {
  let fromBlock = 0;
  let shouldContinueFetching = true;

  while (shouldContinueFetching) {
    const newEvents = await fetchEventsBatch(fromBlock);

    if (newEvents.length < 1000) {
      shouldContinueFetching = false;
    } else {
      const lastBlockNumber = newEvents[newEvents.length - 1].blockNumber;
      fromBlock = lastBlockNumber;
    }

    setEvents((prevEvents) => {
      if (!prevEvents) {
        prevEvents = [];
      }
      const mergedEvents = _.uniqBy(
        _.sortBy(
          [...prevEvents, ...newEvents],
          ["blockNumber", "transactionIndex", "logIndex"]
        ),
        (event) =>
          `${event.blockNumber} ${event.transactionIndex} ${event.logIndex}`
      );

      return mergedEvents;
    });
  }
};

export const addressToTopic = (address) =>
  "0x" + address.slice(2).padStart(64, "0");
