import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import { fetchSigner, getSignerAddress } from "./slice";
import { useChainSetting } from "./hooks";

export const ConnectButton = (props) => {
  const user = useSelector(getSignerAddress);
  const { chainId } = useChainSetting();
  const dispatch = useDispatch();
  if (!user) {
    const hasWallet = window.ethereum && window.ethereum.isMetaMask;
    if (hasWallet) {
      return (
        <Button
          {...props}
          color="warning"
          disabled={false}
          onClick={() => {
            dispatch(fetchSigner({ chainId }));
          }}
        >
          Connect Wallet{props.otherText}
        </Button>
      );
    } else {
      return (
        <Button
          {...props}
          disabled={false}
          color="warning"
          onClick={() => {
            window.open("https://metamask.io/", "_blank", "noopener");
          }}
        >
          Install Wallet{props.otherText}
        </Button>
      );
    }
  } else {
    return <Button {...props} />;
  }
};
export default ConnectButton;
