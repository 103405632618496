import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const act = createAsyncThunk(
  "transaction/act",
  async (args, thunkAPI) => {
    const tr = await args.promise;
    console.log("tr is", tr);
    thunkAPI.dispatch(wait({ tr, title: args.title }));
  }
);

const wait = createAsyncThunk("transaction/wait", async (args, thunkAPI) => {
  const tx = await args.tr.wait();
  return tx.transactionHash;
});

const initialState = {
  transactions: {},
  counter: { fulfilled: 0, pending: 0, rejected: 0 },
  show: null,
};

const slice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    show(state, action) {
      state.show = action.payload;
      if (action.payload === "list") {
        state.counter = { ...state.counter, fulfilled: 0, rejected: 0 };
      }
    },
    hide(state, action) {
      state.show = null;
    },
    closeError(state, action) {
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(act.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(wait.pending, (state, action) => {
        state.transactions[action.meta.requestId] = {
          state: "pending",
          title: action.meta.arg.title,
          createTime: +new Date(),
        };
        state.counter.pending += 1;
      })
      .addCase(wait.fulfilled, (state, action) => {
        const oldState = state.transactions[action.meta.requestId];
        state.transactions[action.meta.requestId] = {
          ...oldState,
          state: "fulfilled",
          result: {
            transactionHash: action.payload,
          },
          updateTime: +new Date(),
        };
        state.counter.pending -= 1;
        state.counter.fulfilled += 1;
      })
      .addCase(wait.rejected, (state, action) => {
        const oldState = state.transactions[action.meta.requestId];
        state.transactions[action.meta.requestId] = {
          ...oldState,
          state: "rejected",
          result: action.error.message,
          updateTime: +new Date(),
        };
        state.counter.pending -= 1;
        state.counter.rejected += 1;
      });
  },
});

export const actions = slice.actions;
export default slice.reducer;
